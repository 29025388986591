import LockWhiteSVG from './lock-white.svg';
import InfoSVG from './info.svg';
import PlusIconSVG from './plus-icon.svg';
import DashIconSVG from './dash-icon.svg';
import CloseSVG from './close.svg';
import PlaydownIconSVG from './play-down.svg';
import CloseIcon from './close-icon.svg';

export {
    LockWhiteSVG,
    InfoSVG,
    PlusIconSVG,
    DashIconSVG,
    CloseSVG,
    PlaydownIconSVG,
    CloseIcon,
};
